import server from './server';

const getInfo = async () => {
  try {
    const info = await server.get('/info');
    return info?.data;
  } catch (error) {
    return error.response?.data;
  }
};

export { getInfo };
