import server from "./server";

const getExperiences = async () => {
  try {
    const experiences = await server.get("/experiences");
    return experiences.data;
  } catch (error) {
    return error.response.data;
  }
};

const getExperience = async (id) => {
  try {
    const experience = await server.get(`/experiences/${id}`);
    return experience.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getExperiences, getExperience };
