import React, { Component } from 'react';

// Import Styling
import './LeftBar.scss';

// Icons
// import { facebook, instagram, squares, twitter } from '../../helper/icons';
import { squares } from '../../helper/icons';
import toptal from '../../assets/icons/toptal.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import facebook from '../../assets/icons/facebook.svg';
import twitter from '../../assets/icons/twitter.svg';
import instagram from '../../assets/icons/instagram.svg';

//Server API
import { getInfo } from '../../api/info';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';

class LeftBar extends Component {
  state = {
    menuOpened: false,
    info: [],
  };

  getInfo = async () => {
    const info = await getInfo();
    if (!info?.success) {
      return toast.error('Something wrong, try again');
    }

    this.setState({ info: info.data });
  };

  async componentDidMount() {
    await this.getInfo();
  }

  render() {
    const social = this.state?.info.length
      ? JSON.parse(
          getObjectByName(this.state?.info, 'option_name', 'social_media')
            .option_value
        )
      : null;

    return (
      <div
        className={`left-bar col-md-3 ${
          this.state.menuOpened ? 'menuOpened' : ''
        }`}
      >
        <div className='wrapper'>
          <div className='top-bar-wrapper'>
            <Link to='/'>
              <img
                className='logo'
                src={
                  process.env.REACT_APP_CONTENT_BASE_URL +
                  getObjectByName(this.state?.info, 'option_name', 'logo')
                    ?.option_value
                }
                alt='Ankit'
              />
            </Link>

            <button
              onClick={() =>
                this.setState({ menuOpened: !this.state.menuOpened })
              }
              className='mobile-menu'
            >
              <i className='fas fa-bars' />
            </button>
          </div>

          {squares}

          <div className='meta'>
            <div className='info'>
              <span>Email</span>
              <a
                style={{ color: '#fff' }}
                href={`mailto:${
                  getObjectByName(this.state?.info, 'option_name', 'email')
                    ?.option_value
                }`}
              >
                {
                  getObjectByName(this.state?.info, 'option_name', 'email')
                    ?.option_value
                }
              </a>
            </div>
            <div className='info'>
              <span>Phone</span>
              <a
                style={{ color: '#fff' }}
                href={`tel:${
                  getObjectByName(this.state?.info, 'option_name', 'phone')
                    ?.option_value
                }`}
              >
                {
                  getObjectByName(this.state?.info, 'option_name', 'phone')
                    ?.option_value
                }
              </a>
            </div>
            <div className='social'>
              <a
                href={social?.toptal}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={toptal} alt={'toptal'} width={25} />
              </a>
              <a
                href={social?.linkedin}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={linkedin} alt={'linkedin'} width={25} />
              </a>
              <a
                href={social?.facebook}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={facebook} alt={'linkedin'} width={25} />
                {/* {facebook} */}
              </a>

              <a
                href={social?.instagram}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={instagram} alt={'linkedin'} width={25} />

                {/* {instagram} */}
              </a>

              <a
                href={social?.twitter}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={twitter} alt={'linkedin'} width={25} />

                {/* {twitter} */}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LeftBar);
