import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import Loader from '../../components/Loader/Loader';
import '../../assets/scss/slick/slick.scss';
import { clock, quote, location, arrow } from '../../helper/icons';
import LeftBar from '../../components/LeftBar/LeftBar';
import Navigation from '../../components/Navigation/Navigation';
import { getInfo } from '../../api/info';
import { getServices } from '../../api/services';
import { getExperiences } from '../../api/work-experiences';
import { getTestimonials } from '../../api/testimonials';
import { getSkills } from '../../api/skills';
import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';
import { Fade } from 'react-awesome-reveal';
import './About.scss';

const About = () => {
  const [loading, setLoading] = useState(true);
  const [thePosition, setThePosition] = useState(0);
  const [siteTitle, setSiteTitle] = useState('');
  const [about, setAbout] = useState('');
  const [services, setServices] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const info = await getInfo();
      const servicesData = await getServices();
      const experiencesData = await getExperiences();
      const testimonialsData = await getTestimonials();
      const skillsData = await getSkills();

      if (
        !info.success ||
        !servicesData.success ||
        !experiencesData.success ||
        !testimonialsData.success ||
        !skillsData.success
      ) {
        return toast.error('Something went wrong, try again');
      }

      setSiteTitle(
        getObjectByName(info.data, 'option_name', 'site_title').option_value
      );
      setAbout(
        getObjectByName(info.data, 'option_name', 'full_description')
          .option_value
      );
      setServices(servicesData.data.reverse());
      setExperiences(experiencesData.data);
      setTestimonials(testimonialsData.data);
      setSkills(skillsData.data.reverse());
      setLoading(false);

      // Naming The Page
      document.title = `${siteTitle} - About`;
    };

    fetchData();
    window.addEventListener('scroll', listenToScroll);

    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, [siteTitle]);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setThePosition(winScroll);
  };

  const settings = {
    nextArrow: <button type='button'>{arrow}</button>,
    prevArrow: <button type='button'>{arrow}</button>,
    adaptiveHeight: true,
  };

  return (
    <div className='About'>
      {loading && <Loader />}
      <LeftBar />

      {/* Main Content */}
      <div className='col-md-9 offset-md-3'>
        <div className='all-content'>
          {/* About Me */}
          <Fade bottom>
            <section className='about-me'>
              <h3>About Me</h3>
              <p>{about}</p>
            </section>
          </Fade>

          {/* Services */}
          <Fade bottom>
            <section className='services'>
              <h3>Services</h3>
              <div className='row'>
                {services.map((service) => (
                  <div
                    className='col-md-6 col-lg-4 mb-4 d-flex align-items-stretch'
                    key={service.id}
                  >
                    <div className='single-service'>
                      <div className='icon'>
                        <img
                          src={`${process.env.REACT_APP_CONTENT_BASE_URL}${service.icon}`}
                          alt={service.name}
                        />
                      </div>
                      <h1>{service.name}</h1>
                      <p>{service.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </Fade>

          {/* Work Experiences */}
          <Fade bottom>
            <section className='work-experience'>
              <h3>Work Experience</h3>
              <div className='works'>
                {experiences.map((work) => (
                  <div className='exp' key={work.id}>
                    <h1>
                      {work.title} at <span>{work.company}</span>
                    </h1>
                    <div className='meta'>
                      <div className='info position'>
                        <span>{work.position || 'Full-time'}</span>
                      </div>
                      <div className='info location'>
                        {location} <span>{work.location}</span>
                      </div>
                      <div className='info time'>
                        {clock}{' '}
                        <span>
                          {new Date(work.from).toLocaleDateString('en-US', {
                            month: 'short',
                            year: 'numeric',
                          })}{' '}
                          -{' '}
                          {work.to
                            ? new Date(work.to).toLocaleDateString('en-US', {
                                month: 'short',
                                year: 'numeric',
                              })
                            : 'Present'}
                        </span>
                      </div>
                    </div>
                    <p>{work.description}</p>
                  </div>
                ))}
                <div className='line' />
              </div>
            </section>
          </Fade>

          {/* Testimonials */}
          <Fade bottom>
            <section className='testimonials'>
              <h3>Testimonials</h3>
              <Slider {...settings}>
                {testimonials.map((item) => (
                  <div className='slide-wrapper' key={item.id}>
                    <div className='single-testi'>
                      <div
                        className='img'
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_CONTENT_BASE_URL}${item.photo})`,
                        }}
                      />
                      <div className='text'>
                        {item.backlink ? (
                          <a
                            href={item.backlink}
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <div className='title'>{item.name}</div>
                          </a>
                        ) : (
                          <div className='title'>{item.name}</div>
                        )}
                        <p>{item.description}</p>
                      </div>
                      {quote}
                    </div>
                  </div>
                ))}
              </Slider>
            </section>
          </Fade>

          {/* Skills */}
          <Fade bottom>
            <section className='skills'>
              <h3>Skills</h3>
              <div className='wrapper'>
                <div className='row'>
                  {skills.map((skill) => (
                    <div className='col-md-6' key={skill.id}>
                      <div className='single-skill'>
                        <h4>
                          {skill.name} <span>{skill.percentage}%</span>
                        </h4>
                        <div className='bar'>
                          <div
                            className='level'
                            style={{
                              width:
                                thePosition >= 100
                                  ? `${skill.percentage}%`
                                  : '0',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </Fade>
        </div>
      </div>

      <Navigation />
    </div>
  );
};

export default withRouter(About);
