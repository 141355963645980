import server from "./server";

const getProjects = async () => {
  try {
    const projects = await server.get("/portfolio");
    return projects.data;
  } catch (error) {
    return error.response.data;
  }
};

const getProject = async (id) => {
  try {
    const project = await server.get(`/portfolio/${id}`);
    return project.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getProjects, getProject };
