import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// Loader
import Loader from '../../components/Loader/Loader';

// Components
import LeftBar from '../../components/LeftBar/LeftBar';
import Navigation from '../../components/Navigation/Navigation';

// Animation
import { Fade } from 'react-awesome-reveal';

// Server API
import { getInfo } from '../../api/info';
import { sendEmail } from '../../api/email';

import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';

// Google Recaptcha
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import './Contact.scss';

const Contact = (props) => {
  const [loader, setLoader] = useState(true);
  const [siteTitle, setSiteTitle] = useState('');
  const [map, setMap] = useState('');

  // Inputs
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Loader
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  const getData = async () => {
    const info = await getInfo();

    if (!info.success) {
      return toast.error('Something wrong, try again');
    }

    setSiteTitle(
      getObjectByName(info.data, 'option_name', 'site_title').option_value
    );
    setMap(getObjectByName(info.data, 'option_name', 'map').option_value);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (!recaptchaToken) {
        toast.error('Please verify you are not a robot!');
        setLoading(false);
        return;
      }

      if (messageCount >= 5) {
        toast.error('You have reached the limit of sending 5 messages.');
        setLoading(false);
        return;
      }

      if (url) {
        toast.error('Get lost, you bot!');
        setLoading(false);
        return;
      }

      if (!name || !email || !message) {
        toast.error('All fields are required');
        setLoading(false);
        return;
      }

      const emailMessage = `
        <strong>Name:</strong> ${name} <br /> 
        <strong>Email:</strong> ${email} <br /> 
        <strong>Message:</strong> ${message} 
      `;

      const sendResponse = await sendEmail({
        text: emailMessage,
        recaptchaToken,
      });

      if (!sendResponse.success) {
        toast.error(sendResponse.message);
        setLoading(false);
        return;
      }

      toast.success(sendResponse.message);
      setLoading(false);
      setRefreshReCaptcha(!refreshReCaptcha);
      setMessageCount(messageCount + 1);
    } catch (err) {
      setRefreshReCaptcha(!refreshReCaptcha);
      setLoading(false);
    }
  };

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getData();
      setLoader(false);
      // Naming The Page
      document.title = `${siteTitle} - Contact`;
    };
    fetchData();
  }, [siteTitle]);

  return (
    <div className='Contact'>
      {loader && <Loader />}
      <LeftBar />

      <div className='col-md-9 offset-md-3'>
        <div className='all-content'>
          <section>
            <h3>Contact Me</h3>

            <div className='entry'>
              <Fade bottom>
                <div
                  className='map'
                  dangerouslySetInnerHTML={{ __html: map }}
                />
              </Fade>
              <Fade bottom>
                <form onSubmit={sendMessage}>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={
                      process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY
                    }
                  >
                    <GoogleReCaptcha
                      onVerify={handleRecaptchaVerify}
                      refreshReCaptcha={refreshReCaptcha}
                      style={{ display: 'none' }}
                    />
                  </GoogleReCaptchaProvider>
                  <div className='row'>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        name='full-name'
                        id='full-name'
                        placeholder='Your name'
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className='col-md-6'>
                      <input
                        type='email'
                        name='e-mail'
                        id='e-mail'
                        placeholder='Your email'
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <p style={{ display: 'none' }}>
                      <input
                        type='text'
                        name='url'
                        placeholder='Your Website'
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </p>

                    <div className='col-md-12 position-relative'>
                      <textarea
                        name='comment'
                        id='comment'
                        rows={7}
                        placeholder='Write a message...'
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className='col-md-3'>
                      <button className='send' type='submit'>
                        {loading ? (
                          <i className='fas fa-spinner fa-spin' />
                        ) : (
                          'Send Message'
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Fade>
            </div>
          </section>
        </div>
      </div>

      <Navigation />
    </div>
  );
};

export default withRouter(Contact);
