import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// Loader
import Loader from '../../components/Loader/Loader';

// Components
import LeftBar from '../../components/LeftBar/LeftBar';
import Navigation from '../../components/Navigation/Navigation';

// Icons
import { search } from '../../helper/icons';

// Animation
import { Fade } from 'react-awesome-reveal';

// Server API
import { getInfo } from '../../api/info';
import { getProjects } from '../../api/portfolio';
import { getCategories } from '../../api/categories';
import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';
import './Portfolio.scss';
const Portfolio = (props) => {
  const [loading, setLoading] = useState(true);
  const [getAllPortfolio, setGetAllPortfolio] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [siteTitle, setSiteTitle] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState('All');

  const getData = async () => {
    try {
      const info = await getInfo();
      const portfolioResponse = await getProjects();
      const categoriesResponse = await getCategories();
      const categoriesNames = categoriesResponse.data.map(
        (category) => category.name
      );

      if (
        !info.success ||
        !portfolioResponse.success ||
        !categoriesResponse.success
      ) {
        return toast.error('Something wrong, try again');
      }

      setSiteTitle(
        getObjectByName(info.data, 'option_name', 'site_title').option_value
      );
      setGetAllPortfolio(portfolioResponse.data);
      setPortfolio(portfolioResponse.data);
      setCategories(['All', ...categoriesNames]);
    } catch (error) {
      toast.error('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // Naming The Page
    document.title = `${siteTitle} - Portfolio`;
  }, [siteTitle]); // Adding siteTitle as a dependency

  const filterItems = (e, category) => {
    e.preventDefault();
    setSelectedCat(category);
    if (category === 'All') {
      setPortfolio(getAllPortfolio);
    } else {
      setPortfolio(
        getAllPortfolio.filter(
          (item) => item.portfolio_category.name === category
        )
      );
    }
  };

  // Import Styling
  require('./Portfolio.scss');

  const portfolioItems = portfolio.map((item) => (
    <div className='col-md-4' key={item.id}>
      <Fade bottom>
        <Link to={`/portfolio/${item._id}`}>
          <div className='work'>
            <div className='thumbnail'>
              <div className='overlay'>{search}</div>
              <img
                src={process.env.REACT_APP_CONTENT_BASE_URL + item.thumbnail}
                alt={item.name}
              />
            </div>
            <h3>{item.name}</h3>
            <span>{item.portfolio_category.name}</span>
          </div>
        </Link>
      </Fade>
    </div>
  ));

  const categoriesList = categories.map((cat) => (
    <a
      key={cat}
      href='#'
      className={cat === selectedCat ? 'active' : ''}
      onClick={(e) => filterItems(e, cat)}
    >
      {cat}
    </a>
  ));

  return (
    <div className='Portfolio'>
      {loading && <Loader />}
      <LeftBar />

      <div className='col-md-9 offset-md-3'>
        <div className='all-content'>
          <Fade bottom>
            <section>
              <h3>Portfolio</h3>
              <div className='categories'>{categoriesList}</div>
            </section>
          </Fade>

          <div className='works'>
            <div className='row'>{portfolioItems}</div>
          </div>
        </div>
      </div>

      <Navigation />
    </div>
  );
};

export default withRouter(Portfolio);
