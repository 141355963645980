import server from "./server";

const getCategories = async () => {
  try {
    const categories = await server.get("/categories");
    return categories.data;
  } catch (error) {
    return error.response.data;
  }
};

const getCategory = async (id) => {
  try {
    const category = await server.get(`/categories/${id}`);
    return category.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getCategories, getCategory };
