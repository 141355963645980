import server from "./server";

const getServices = async () => {
  try {
    const services = await server.get("/services");
    return services.data;
  } catch (error) {
    return error.response.data;
  }
};

const getService = async (id) => {
  try {
    const service = await server.get(`/services/${id}`);
    return service.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getServices, getService };
