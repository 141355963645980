import server from "./server";

const getSkills = async () => {
  try {
    const skills = await server.get("/skills");
    return skills.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSkill = async (id) => {
  try {
    const skill = await server.get(`/skills/${id}`);
    return skill.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getSkills, getSkill };
