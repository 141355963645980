import React from 'react';

// About Page
export const clock = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
  >
    <path
      className='icon'
      d='M8,16A8,8,0,1,0,0,8,8.009,8.009,0,0,0,8,16ZM7.333,1.367V2A.667.667,0,0,0,8.667,2V1.367a6.678,6.678,0,0,1,5.966,5.966H14a.667.667,0,0,0,0,1.333h.633a6.678,6.678,0,0,1-5.966,5.966V14a.667.667,0,0,0-1.333,0v.633A6.678,6.678,0,0,1,1.367,8.667H2A.667.667,0,1,0,2,7.333H1.367A6.678,6.678,0,0,1,7.333,1.367Zm0,0'
    />
    <path
      className='icon'
      d='M177,116h3.333a.667.667,0,0,0,0-1.333h-3v-2a.667.667,0,1,0-1.333,0V115A1,1,0,0,0,177,116Zm0,0'
      transform='translate(-168.667 -107.333)'
    />
  </svg>
);

export const location = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='16'
    viewBox='0 0 12 16'
  >
    <g transform='translate(0 0)'>
      <path
        className='icon'
        d='M8.744,11.666a.668.668,0,0,0,.922-.2C11.215,9.085,12,7.244,12,6A6,6,0,1,0,0,6c0,3.1,4.927,9.088,5.488,9.761A.668.668,0,0,0,6,16H6a.668.668,0,0,0,.511-.239c.031-.036.758-.908,1.68-2.167a.667.667,0,0,0-1.076-.788c-.433.591-.826,1.1-1.117,1.468C4.339,12.184,1.334,7.988,1.334,6a4.667,4.667,0,1,1,9.333,0c0,.955-.753,2.639-2.119,4.744a.667.667,0,0,0,.2.922Zm0,0'
        transform='translate(0 0)'
      />
      <path
        className='icon'
        d='M88,74.333A2.333,2.333,0,1,0,90.333,72,2.336,2.336,0,0,0,88,74.333Zm3.333,0a1,1,0,1,1-1-1A1,1,0,0,1,91.333,74.333Zm0,0'
        transform='translate(-84.333 -69)'
      />
    </g>
  </svg>
);

export const position = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='16'
    viewBox='0 0 12 16'
  >
    <g transform='translate(0 0)'>
      <path
        className='icon'
        d='M8.744,11.666a.668.668,0,0,0,.922-.2C11.215,9.085,12,7.244,12,6A6,6,0,1,0,0,6c0,3.1,4.927,9.088,5.488,9.761A.668.668,0,0,0,6,16H6a.668.668,0,0,0,.511-.239c.031-.036.758-.908,1.68-2.167a.667.667,0,0,0-1.076-.788c-.433.591-.826,1.1-1.117,1.468C4.339,12.184,1.334,7.988,1.334,6a4.667,4.667,0,1,1,9.333,0c0,.955-.753,2.639-2.119,4.744a.667.667,0,0,0,.2.922Zm0,0'
        transform='translate(0 0)'
      />
      <path
        className='icon'
        d='M88,74.333A2.333,2.333,0,1,0,90.333,72,2.336,2.336,0,0,0,88,74.333Zm3.333,0a1,1,0,1,1-1-1A1,1,0,0,1,91.333,74.333Zm0,0'
        transform='translate(-84.333 -69)'
      />
    </g>
  </svg>
);

export const quote = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='89.5'
    height='89.5'
    viewBox='0 0 89.5 89.5'
  >
    <g transform='translate(-8 -8)'>
      <path
        className='quote'
        d='M52.75,8A44.75,44.75,0,1,0,97.5,52.75,44.8,44.8,0,0,0,52.75,8Zm0,82.042A37.292,37.292,0,1,1,90.042,52.75,37.334,37.334,0,0,1,52.75,90.042Z'
      />
      <path
        className='quote'
        d='M36.646,23H21.729A3.731,3.731,0,0,0,18,26.729V41.646a3.731,3.731,0,0,0,3.729,3.729h10.3A8.29,8.29,0,0,1,28.307,49.1l-.789.4a3.728,3.728,0,0,0,1.671,7.063,3.691,3.691,0,0,0,1.667-.4l.787-.393a15.708,15.708,0,0,0,8.732-14.128V26.729A3.731,3.731,0,0,0,36.646,23ZM25.458,30.458h7.458v7.458H25.458Z'
        transform='translate(8.646 12.969)'
      />
      <path
        className='quote'
        d='M52.646,23H37.729A3.731,3.731,0,0,0,34,26.729V41.646a3.731,3.731,0,0,0,3.729,3.729h10.3A8.282,8.282,0,0,1,44.309,49.1l-.791.4a3.73,3.73,0,0,0,3.338,6.671l.791-.4a15.712,15.712,0,0,0,8.728-14.128V26.729A3.731,3.731,0,0,0,52.646,23ZM41.458,30.458h7.458v7.458H41.458Z'
        transform='translate(22.479 12.969)'
      />
    </g>
  </svg>
);

export const arrow = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11'
    height='9.167'
    viewBox='0 0 11 9.167'
  >
    <path
      className='arrow'
      d='M18.542,16.125H9.4l3.5-3.335a.458.458,0,1,0-.632-.664l-4,3.809a.916.916,0,0,0,.008,1.3l3.991,3.8a.458.458,0,1,0,.632-.664L9.384,17.042h9.158a.458.458,0,1,0,0-.917Z'
      transform='translate(19 21.167) rotate(180)'
    />
  </svg>
);

// Home Page
export const downloadIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='15.324'
    height='15.324'
    viewBox='0 0 15.324 15.324'
  >
    <path
      className='arrow'
      d='M457.2,480.958h-.718a.479.479,0,1,1,0-.958h.718a.479.479,0,1,1,0,.958Z'
      transform='translate(-442.352 -465.634)'
    />
    <path
      className='arrow'
      d='M1.2,480.958H.479a.479.479,0,1,1,0-.958H1.2a.479.479,0,1,1,0,.958Z'
      transform='translate(0 -465.634)'
    />
    <path
      className='arrow'
      d='M90.056,480.958H80.479a.479.479,0,1,1,0-.958h9.577a.479.479,0,1,1,0,.958Z'
      transform='translate(-77.606 -465.634)'
    />
    <path
      className='arrow'
      d='M77.895,13.275a1.372,1.372,0,0,1-.974-.4L72.887,8.838a1.656,1.656,0,0,1,1.164-2.832h.006a1.7,1.7,0,0,1,1.2.494l.964.963V1.676a1.676,1.676,0,0,1,3.352,0V7.464l.978-.978a1.656,1.656,0,0,1,2.832,1.164,1.705,1.705,0,0,1-.494,1.2l-4.019,4.019A1.372,1.372,0,0,1,77.895,13.275ZM74.057,6.964h0a.689.689,0,0,0-.493.2.706.706,0,0,0,0,1L77.6,12.2a.419.419,0,0,0,.593,0l4.019-4.019a.741.741,0,0,0,.214-.524.689.689,0,0,0-.2-.493.706.706,0,0,0-1,0l-1.8,1.8a.479.479,0,0,1-.817-.339V1.676a.718.718,0,0,0-1.437,0V8.62a.479.479,0,0,1-.817.339L74.578,7.177a.741.741,0,0,0-.521-.214Z'
      transform='translate(-70.233 0)'
    />
  </svg>
);

export const contactIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14.957'
    height='14.964'
    viewBox='0 0 14.957 14.964'
  >
    <g transform='translate(-0.116 0)'>
      <path
        className='arrow'
        d='M15.076.742a.614.614,0,0,0-.495-.725c-.246-.044.692-.34-14.045,5.019a.614.614,0,0,0-.373.772,13.574,13.574,0,0,0,3.282,5.28,14.126,14.126,0,0,0,5.843,3.844.614.614,0,0,0,.772-.373C15.426-.2,15.063.807,15.076.742ZM1.532,5.981l10.55-3.837-7.92,7.92a12.39,12.39,0,0,1-2.63-4.083Zm7.583,7.583a12.391,12.391,0,0,1-4.083-2.63l7.92-7.92Z'
        transform='translate(-0.015 0)'
      />
      <path
        className='arrow'
        d='M107.611,448.429l-.624.624a.614.614,0,0,0,.869.869l.624-.624a.614.614,0,0,0-.869-.869Z'
        transform='translate(-103.57 -435.139)'
      />
      <path
        className='arrow'
        d='M.731,343.435a.612.612,0,0,0,.434-.18l.624-.624a.614.614,0,1,0-.869-.869l-.624.624A.615.615,0,0,0,.731,343.435Z'
        transform='translate(0 -331.592)'
      />
      <path
        className='arrow'
        d='M2.19,405.762.318,407.634a.614.614,0,0,0,.869.869l1.872-1.872a.614.614,0,0,0-.869-.869Z'
        transform='translate(-0.021 -393.719)'
      />
    </g>
  </svg>
);

// Main
export const squares = (
  <svg
    className='squares'
    xmlns='http://www.w3.org/2000/svg'
    width='114'
    height='114'
    viewBox='0 0 114 114'
  >
    <g transform='translate(-1204 -35)'>
      <rect className='a' width='9' height='9' transform='translate(1309 35)' />
      <rect className='a' width='9' height='9' transform='translate(1309 95)' />
      <rect className='a' width='9' height='9' transform='translate(1309 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1309 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1309 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1309 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1309 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1309 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1294 35)' />
      <rect className='a' width='9' height='9' transform='translate(1294 95)' />
      <rect className='a' width='9' height='9' transform='translate(1294 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1294 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1294 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1294 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1294 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1294 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1279 35)' />
      <rect className='a' width='9' height='9' transform='translate(1279 95)' />
      <rect className='a' width='9' height='9' transform='translate(1279 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1279 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1279 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1279 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1279 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1279 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1264 35)' />
      <rect className='a' width='9' height='9' transform='translate(1264 95)' />
      <rect className='a' width='9' height='9' transform='translate(1264 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1264 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1264 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1264 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1264 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1264 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1249 35)' />
      <rect className='a' width='9' height='9' transform='translate(1249 95)' />
      <rect className='a' width='9' height='9' transform='translate(1249 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1249 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1249 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1249 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1249 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1249 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1234 35)' />
      <rect className='a' width='9' height='9' transform='translate(1234 95)' />
      <rect className='a' width='9' height='9' transform='translate(1234 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1234 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1234 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1234 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1234 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1234 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1219 35)' />
      <rect className='a' width='9' height='9' transform='translate(1219 95)' />
      <rect className='a' width='9' height='9' transform='translate(1219 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1219 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1219 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1219 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1219 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1219 140)'
      />
      <rect className='a' width='9' height='9' transform='translate(1204 35)' />
      <rect className='a' width='9' height='9' transform='translate(1204 95)' />
      <rect className='a' width='9' height='9' transform='translate(1204 65)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1204 125)'
      />
      <rect className='a' width='9' height='9' transform='translate(1204 50)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1204 110)'
      />
      <rect className='a' width='9' height='9' transform='translate(1204 80)' />
      <rect
        className='a'
        width='9'
        height='9'
        transform='translate(1204 140)'
      />
    </g>
  </svg>
);

export const squaresMain = (
  <svg
    className='squ'
    xmlns='http://www.w3.org/2000/svg'
    width='114'
    height='114'
    viewBox='0 0 114 114'
  >
    <g transform='translate(-1204 -35)'>
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1309 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1294 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1279 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1264 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1249 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1234 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1219 140)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 35)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 95)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 65)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 125)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 50)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 110)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 80)'
      />
      <rect
        className='squares'
        width='9'
        height='9'
        transform='translate(1204 140)'
      />
    </g>
  </svg>
);

// Navigation
export const home = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25.559'
    height='25.559'
    viewBox='0 0 25.559 25.559'
  >
    <path
      className='off'
      d='M25.248,12.027,22.585,9.364,13.533.312a1.065,1.065,0,0,0-1.506,0L2.975,9.364.313,12.027a1.065,1.065,0,1,0,1.506,1.506l.845-.845V24.494a1.065,1.065,0,0,0,1.065,1.065H9.585a1.065,1.065,0,0,0,1.065-1.065v-8.52h4.26v7.455H13.845a1.065,1.065,0,0,0,0,2.13h7.987A1.065,1.065,0,0,0,22.9,24.494V12.688l.845.845a1.065,1.065,0,1,0,1.506-1.506Zm-4.48,11.4H17.04v-8.52a1.065,1.065,0,0,0-1.065-1.065H9.585a1.065,1.065,0,0,0-1.065,1.065v8.52H4.793V10.558L12.78,2.571l7.987,7.987Z'
      transform='translate(-0.001 0)'
    />
  </svg>
);

export const about = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25.823'
    height='25.823'
    viewBox='0 0 25.823 25.823'
  >
    <path
      className='off'
      d='M20.189,14.089a1.055,1.055,0,0,0-.177-.118,12.847,12.847,0,0,0-2.872-1.421,6.994,6.994,0,1,0-8.458,0A12.87,12.87,0,0,0,5.81,13.971a1.077,1.077,0,0,0-.177.118A12.907,12.907,0,0,0,0,24.747a1.076,1.076,0,1,0,2.152,0,10.739,10.739,0,0,1,4.061-8.405l5.938,5.938a1.075,1.075,0,0,0,1.521,0l5.938-5.938a10.739,10.739,0,0,1,4.061,8.405,1.076,1.076,0,1,0,2.152,0A12.9,12.9,0,0,0,20.189,14.089ZM8.07,6.994a4.842,4.842,0,1,1,4.842,4.842A4.847,4.847,0,0,1,8.07,6.994Zm4.842,13L8.067,15.152a10.653,10.653,0,0,1,9.689,0Z'
    />
    <g transform='translate(5.38 22.057)'>
      <path
        className='off'
        d='M81.076,328A1.076,1.076,0,0,0,80,329.076v1.614a1.076,1.076,0,0,0,2.152,0v-1.614A1.076,1.076,0,0,0,81.076,328Z'
        transform='translate(-80 -328)'
      />
    </g>
    <g transform='translate(18.829 22.057)'>
      <path
        className='off'
        d='M281.076,328A1.076,1.076,0,0,0,280,329.076v1.614a1.076,1.076,0,0,0,2.152,0v-1.614A1.076,1.076,0,0,0,281.076,328Z'
        transform='translate(-280 -328)'
      />
    </g>
  </svg>
);

export const portfolio = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='26'
    height='26'
    viewBox='0 0 26 26'
  >
    <path
      className='off'
      d='M24.917,5.417H20.583V1.083A1.084,1.084,0,0,0,19.5,0H6.5A1.084,1.084,0,0,0,5.417,1.083V5.417H1.083A1.084,1.084,0,0,0,0,6.5V24.917A1.084,1.084,0,0,0,1.083,26H24.917A1.084,1.084,0,0,0,26,24.917V6.5A1.084,1.084,0,0,0,24.917,5.417ZM7.583,2.167H18.417v3.25H7.583ZM2.167,23.833v-9.75H3.25a1.083,1.083,0,1,0,0-2.167H2.167V7.583H23.833v4.333H7.583a1.083,1.083,0,1,0,0,2.167H8.667v3.25A1.084,1.084,0,0,0,9.75,18.417h6.5a1.084,1.084,0,0,0,1.083-1.083v-3.25h6.5v9.75Zm8.667-9.75h4.333V16.25H10.833Zm0,0'
    />
  </svg>
);

export const blog = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25.823'
    height='25.822'
    viewBox='0 0 25.823 25.822'
  >
    <g transform='translate(-1218 -323)'>
      <path
        className='off'
        d='M24.746,4.3h-4.3V1.076A1.076,1.076,0,0,0,19.367,0H1.076A1.076,1.076,0,0,0,0,1.076V24.746a1.076,1.076,0,0,0,1.076,1.076H3.228a1.076,1.076,0,0,0,0-2.152H2.152V2.152H18.291v19.9a3.741,3.741,0,0,0,.369,1.614H7.532a1.076,1.076,0,0,0,0,2.152H22.057a3.77,3.77,0,0,0,3.766-3.766V5.38A1.076,1.076,0,0,0,24.747,4.3ZM23.671,22.057a1.614,1.614,0,0,1-3.228,0V6.456h3.228Zm0,0'
        transform='translate(1218 323)'
      />
      <path
        className='off'
        d='M65.076,70.456h4.3a1.076,1.076,0,0,0,1.076-1.076v-4.3A1.076,1.076,0,0,0,69.38,64h-4.3A1.076,1.076,0,0,0,64,65.076v4.3A1.076,1.076,0,0,0,65.076,70.456Zm1.076-4.3H68.3V68.3H66.152Zm0,0'
        transform='translate(1158.304 263.304)'
      />
      <path
        className='off'
        d='M65.076,186.152h4.3a1.076,1.076,0,1,0,0-2.152h-4.3a1.076,1.076,0,1,0,0,2.152Zm0,0'
        transform='translate(1158.304 151.373)'
      />
      <path
        className='off'
        d='M178.152,186.152a1.076,1.076,0,1,0,0-2.152h-1.076a1.076,1.076,0,0,0,0,2.152Zm0,0'
        transform='translate(1053.835 151.373)'
      />
      <path
        className='off'
        d='M65.076,234.152h8.607a1.076,1.076,0,1,0,0-2.152H65.076a1.076,1.076,0,1,0,0,2.152Zm0,0'
        transform='translate(1158.304 106.601)'
      />
      <path
        className='off'
        d='M74.759,281.076A1.076,1.076,0,0,0,73.683,280H65.076a1.076,1.076,0,1,0,0,2.152h8.607A1.076,1.076,0,0,0,74.759,281.076Zm0,0'
        transform='translate(1158.304 61.829)'
      />
    </g>
  </svg>
);

// Left Bar
export const dribbble = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.322'
    height='18.322'
    viewBox='0 0 18.322 18.322'
  >
    <path
      className='b'
      d='M17.161,8a9.161,9.161,0,1,0,9.161,9.161A9.171,9.171,0,0,0,17.161,8ZM24.8,17.161c0,.128-.013.253-.019.381a17.829,17.829,0,0,0-5.084.608,20.562,20.562,0,0,0-.565-2.081c-.126-.384-.268-.765-.418-1.141a14.372,14.372,0,0,0,4.156-2.822A7.594,7.594,0,0,1,24.8,17.161ZM21.744,11.07a12.808,12.808,0,0,1-3.648,2.46,16.565,16.565,0,0,0-2.54-3.83,7.563,7.563,0,0,1,6.188,1.37Zm-7.783-.831a14.377,14.377,0,0,1,2.723,3.877c-.376.139-.762.273-1.161.393a16.473,16.473,0,0,1-5.739.72A7.655,7.655,0,0,1,13.961,10.239ZM9.527,17.161c0-.142.014-.282.021-.422.364.029.734.05,1.116.05a18.8,18.8,0,0,0,5.3-.816c.463-.139.907-.295,1.34-.457.134.34.262.683.376,1.03a19.018,19.018,0,0,1,.55,2.044,15.546,15.546,0,0,0-6.471,3.958A7.609,7.609,0,0,1,9.527,17.161Zm3.409,6.353a13.847,13.847,0,0,1,5.541-3.395,12.891,12.891,0,0,1-.212,4.587,7.458,7.458,0,0,1-5.329-1.192Zm6.98.758a15.389,15.389,0,0,0,.039-4.611,16.83,16.83,0,0,1,4.59-.6A7.657,7.657,0,0,1,19.917,24.273Z'
      transform='translate(-8 -8)'
    />
  </svg>
);

export const facebook = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.322'
    height='18.322'
    viewBox='0 0 18.322 18.322'
  >
    <path
      className='b'
      d='M20.978,8H13.344A5.35,5.35,0,0,0,8,13.344v7.634a5.35,5.35,0,0,0,5.344,5.344h7.634a5.35,5.35,0,0,0,5.344-5.344V13.344A5.35,5.35,0,0,0,20.978,8ZM16.4,21.741a.764.764,0,0,0-.763-.763H14.107V19.451h1.527a.764.764,0,0,0,.763-.763V15.634a2.293,2.293,0,0,1,2.29-2.29h3.054v1.527h-2.29A1.528,1.528,0,0,0,17.924,16.4v2.29a.764.764,0,0,0,.763.763h2.925l-.445,1.527H18.688a.764.764,0,0,0-.763.763V24.8H16.4Zm8.4-.763A3.821,3.821,0,0,1,20.978,24.8H19.451V22.5h2.29a.763.763,0,0,0,.733-.55l.734-2.518a1.145,1.145,0,0,0-1.085-1.513H19.451V16.4H22.5a.764.764,0,0,0,.763-.763V12.58a.764.764,0,0,0-.763-.763H18.688a3.821,3.821,0,0,0-3.817,3.817v2.29H13.344a.764.764,0,0,0-.763.763v3.054a.764.764,0,0,0,.763.763h1.527V24.8H13.344a3.821,3.821,0,0,1-3.817-3.817V13.344a3.821,3.821,0,0,1,3.817-3.817h7.634A3.821,3.821,0,0,1,24.8,13.344Z'
      transform='translate(-8 -8)'
    />
  </svg>
);

export const instagram = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.322'
    height='18.322'
    viewBox='0 0 18.322 18.322'
  >
    <path
      className='b'
      d='M20.978,8H13.344A5.35,5.35,0,0,0,8,13.344v7.634a5.35,5.35,0,0,0,5.344,5.344h7.634a5.35,5.35,0,0,0,5.344-5.344V13.344A5.35,5.35,0,0,0,20.978,8ZM24.8,20.978A3.821,3.821,0,0,1,20.978,24.8H13.344a3.821,3.821,0,0,1-3.817-3.817V13.344a3.821,3.821,0,0,1,3.817-3.817h7.634A3.821,3.821,0,0,1,24.8,13.344Z'
      transform='translate(-8 -8)'
    />
    <path
      className='b'
      d='M25.2,21a4.2,4.2,0,1,0,4.2,4.2A4.2,4.2,0,0,0,25.2,21Zm0,6.871A2.672,2.672,0,1,1,27.871,25.2,2.675,2.675,0,0,1,25.2,27.871Z'
      transform='translate(-16.038 -16.038)'
    />
    <path
      className='b'
      d='M43.145,16a1.145,1.145,0,1,0,1.145,1.145A1.147,1.147,0,0,0,43.145,16Z'
      transform='translate(-29.022 -12.946)'
    />
  </svg>
);

export const twitter = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.322'
    height='18.322'
    viewBox='0 0 18.322 18.322'
  >
    <path
      className='b'
      d='M20.978,8H13.344A5.35,5.35,0,0,0,8,13.344v7.634a5.35,5.35,0,0,0,5.344,5.344h7.634a5.35,5.35,0,0,0,5.344-5.344V13.344A5.35,5.35,0,0,0,20.978,8ZM24.8,20.978A3.821,3.821,0,0,1,20.978,24.8H13.344a3.821,3.821,0,0,1-3.817-3.817V13.344a3.821,3.821,0,0,1,3.817-3.817h7.634A3.821,3.821,0,0,1,24.8,13.344Z'
      transform='translate(-8 -8)'
    />
    <path
      className='b'
      d='M26.87,23.634h-2.29V22.489h.764a2.29,2.29,0,0,0,0-4.58h-.8A2.29,2.29,0,0,0,20,18.29V24.4a3.821,3.821,0,0,0,3.817,3.817H26.87a2.29,2.29,0,1,0,0-4.58Zm0,3.054H23.816a2.293,2.293,0,0,1-2.29-2.29V18.29a.763.763,0,0,1,1.527,0v.382a.762.762,0,0,0,.763.763h1.527a.763.763,0,0,1,0,1.527H23.816a.764.764,0,0,0-.763.763v1.909a1.528,1.528,0,0,0,1.527,1.527h2.29a.763.763,0,1,1,0,1.527Z'
      transform='translate(-15.419 -12.946)'
    />
  </svg>
);

// Portfolio
export const search = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='27.769'
    height='27.769'
    viewBox='0 0 27.769 27.769'
  >
    <g transform='translate(-0.488 0)'>
      <path
        style={{ fill: '#fff' }}
        d='M4.605,17.835a1.157,1.157,0,1,0,1.348-1.882,7.518,7.518,0,1,1,4.371,1.4,1.157,1.157,0,0,0,0,2.314,9.759,9.759,0,0,0,3.911-.816l7.9,7.9a3.474,3.474,0,0,0,4.909,0l.2-.2a3.476,3.476,0,0,0,0-4.909l-7.9-7.9A9.833,9.833,0,1,0,.488,9.835a9.862,9.862,0,0,0,4.117,8Zm21,5.448a1.159,1.159,0,0,1,0,1.637l-.2.2a1.159,1.159,0,0,1-1.637,0L16.29,17.637A9.9,9.9,0,0,0,18.125,15.8Zm0,0'
      />
      <path
        style={{ fill: '#fff' }}
        d='M121.645,58.314a3.475,3.475,0,0,1,3.471,3.471,1.157,1.157,0,0,0,2.314,0A5.792,5.792,0,0,0,121.645,56a1.157,1.157,0,0,0,0,2.314Zm0,0'
        transform='translate(-111.321 -51.95)'
      />
    </g>
  </svg>
);

export const globe = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
  >
    <path
      className='main-color'
      d='M17.963,8.182a8.966,8.966,0,0,0-2-4.885h0A9,9,0,0,0,.153,7.356v0a0,0,0,0,1,0,0v.006s0,0,0,.007a8.849,8.849,0,0,0-.065,2.852,8.818,8.818,0,0,0,.9,2.868,9.016,9.016,0,0,0,7.592,4.895h.051C8.749,18,8.873,18,9,18a8.993,8.993,0,0,0,9-9C18,8.724,17.988,8.457,17.963,8.182ZM2.124,12a7.334,7.334,0,0,1-.553-1.981A7.2,7.2,0,0,1,1.5,9a7.473,7.473,0,0,1,.037-.75H2.813A.188.188,0,0,1,3,8.438v.75a1.69,1.69,0,0,0,1.688,1.687.188.188,0,0,1,.187.188v.75A.188.188,0,0,1,4.688,12Zm5.751,3.563v.851A7.52,7.52,0,0,1,3,13.5H4.688a1.69,1.69,0,0,0,1.687-1.687v-.75A1.69,1.69,0,0,0,4.688,9.375.188.188,0,0,1,4.5,9.187v-.75A1.69,1.69,0,0,0,2.813,6.75H1.844a7.505,7.505,0,0,1,11.69-3.728H9A1.877,1.877,0,0,0,7.125,4.9V7.313A1.69,1.69,0,0,0,8.813,9,.188.188,0,0,1,9,9.187v1.125A1.69,1.69,0,0,0,10.688,12a.188.188,0,0,1,.187.188v1.5a.188.188,0,0,1-.187.188H9.562A1.69,1.69,0,0,0,7.875,15.562ZM16.348,10.5h-2.1a.75.75,0,0,0,0,1.5h1.623a7.486,7.486,0,0,1-6.5,4.491v-.928a.188.188,0,0,1,.188-.187h1.125a1.69,1.69,0,0,0,1.688-1.687v-1.5A1.69,1.69,0,0,0,10.687,10.5a.188.188,0,0,1-.187-.187V9.187A1.69,1.69,0,0,0,8.812,7.5a.188.188,0,0,1-.187-.187V4.9A.375.375,0,0,1,9,4.522h6.021a7.467,7.467,0,0,1,1.448,3.8c.021.229.031.452.031.682A7.531,7.531,0,0,1,16.348,10.5Z'
    />
    <g transform='translate(11.625 7.5)'>
      <path
        className='main-color'
        d='M250.25,160h-1.5a.75.75,0,0,0,0,1.5h1.5a.75.75,0,0,0,0-1.5Z'
        transform='translate(-248 -160)'
      />
    </g>
  </svg>
);

// Blog Page
export const newspaper = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36.062'
    height='36.062'
    viewBox='0 0 36.062 36.062'
  >
    <g transform='translate(-1218 -323)'>
      <path
        style={{ fill: '#fff' }}
        d='M34.56,6.01h-6.01V1.5a1.5,1.5,0,0,0-1.5-1.5H1.5A1.5,1.5,0,0,0,0,1.5V34.56a1.5,1.5,0,0,0,1.5,1.5H4.508a1.5,1.5,0,0,0,0-3.005h-1.5V3.005H25.544V30.8a5.224,5.224,0,0,0,.515,2.254H10.518a1.5,1.5,0,1,0,0,3.005H30.8A5.265,5.265,0,0,0,36.062,30.8V7.513a1.5,1.5,0,0,0-1.5-1.5ZM33.057,30.8a2.254,2.254,0,1,1-4.508,0V9.016h4.508Zm0,0'
        transform='translate(1218 323)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M65.5,73.016h6.01a1.5,1.5,0,0,0,1.5-1.5V65.5a1.5,1.5,0,0,0-1.5-1.5H65.5A1.5,1.5,0,0,0,64,65.5v6.01A1.5,1.5,0,0,0,65.5,73.016Zm1.5-6.01H70.01V70.01H67.005Zm0,0'
        transform='translate(1160.01 265.01)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M65.5,187.005h6.01a1.5,1.5,0,1,0,0-3.005H65.5a1.5,1.5,0,1,0,0,3.005Zm0,0'
        transform='translate(1160.01 156.28)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M179.005,187.005a1.5,1.5,0,1,0,0-3.005h-1.5a1.5,1.5,0,0,0,0,3.005Zm0,0'
        transform='translate(1058.529 156.28)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M65.5,235.005H77.523a1.5,1.5,0,1,0,0-3.005H65.5a1.5,1.5,0,1,0,0,3.005Zm0,0'
        transform='translate(1160.01 112.788)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M79.026,281.5a1.5,1.5,0,0,0-1.5-1.5H65.5a1.5,1.5,0,1,0,0,3.005H77.523A1.5,1.5,0,0,0,79.026,281.5Zm0,0'
        transform='translate(1160.01 69.295)'
      />
    </g>
  </svg>
);

export const reply = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.462'
    height='10'
    viewBox='0 0 18.462 10'
  >
    <path
      className='main-color'
      d='M22.423,20.923h-11.8l.61-.61a.769.769,0,0,0-1.088-1.088L8.226,21.149a.769.769,0,0,0,0,1.088l1.923,1.923a.769.769,0,0,0,1.088-1.088l-.61-.61h11.8a2.5,2.5,0,0,1,0,5H20.308a.769.769,0,0,0,0,1.538h2.115a4.038,4.038,0,1,0,0-8.077Z'
      transform='translate(-8 -19)'
    />
  </svg>
);

export const send = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.691'
    height='18.691'
    viewBox='0 0 18.691 18.691'
  >
    <g transform='translate(-8 26.691) rotate(-90)'>
      <path
        style={{ fill: '#fff' }}
        d='M8.97,27.008a.783.783,0,0,0-.881.661,9.2,9.2,0,0,0,0,2.556.779.779,0,0,0,.77.669.822.822,0,0,0,.111-.008.779.779,0,0,0,.661-.881,7.6,7.6,0,0,1,0-2.116A.779.779,0,0,0,8.97,27.008Z'
        transform='translate(0 -11.602)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M18.811,8a9.313,9.313,0,0,0-8.326,5.1.779.779,0,0,0,1.387.709,7.788,7.788,0,1,1,0,7.079.779.779,0,0,0-1.387.709A9.346,9.346,0,1,0,18.811,8Z'
        transform='translate(-1.465)'
      />
      <path
        style={{ fill: '#fff' }}
        d='M16,25.894a.779.779,0,0,0,.779.779H25.8l-1.786,1.786a.779.779,0,1,0,1.1,1.1l3.115-3.115a.779.779,0,0,0,0-1.1l-3.115-3.115a.779.779,0,1,0-1.1,1.1L25.8,25.115H16.779a.779.779,0,0,0-.779.779Z'
        transform='translate(-4.885 -8.548)'
      />
    </g>
  </svg>
);
