import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Gravatar from 'react-gravatar';

// Loader
import Loader from '../../components/Loader/Loader';

// Components
import LeftBar from '../../components/LeftBar/LeftBar';
import Navigation from '../../components/Navigation/Navigation';

// Icons
import { send } from '../../helper/icons';

// Animation
import { Fade } from 'react-awesome-reveal';

// Server API
import { getInfo } from '../../api/info';
import { getArticle } from '../../api/blog';
import { getComments, addComment } from '../../api/comments';
import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';
import './SingleBlog.scss';
const SingleBlog = (props) => {
  const [loading, setLoading] = useState(true);
  const [siteTitle, setSiteTitle] = useState('');
  const [content, setContent] = useState({});
  const [comments, setComments] = useState([]);

  // Comment Inputs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  const id = props.router.params.id;

  const getData = async () => {
    try {
      const info = await getInfo();
      const article = await getArticle(id);
      const commentsResponse = await getComments(id);

      if (!article.success || !info.success) {
        return toast.error('Something wrong, try again');
      }

      setSiteTitle(
        getObjectByName(info.data, 'option_name', 'site_title').option_value
      );
      setContent(article.data);
      setComments(commentsResponse.data);
    } catch (error) {
      toast.error('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const addCommentHandler = async (e) => {
    e.preventDefault();

    if (!name || !email || !comment) {
      return toast.error('All fields are required');
    }

    const sendComment = await addComment({
      name,
      comment,
      email,
      blogID: id,
    });

    if (!sendComment.success) {
      return toast.error(sendComment.message);
    }

    await getData();
    toast.success('Comment added successfully');

    // Reset input fields
    setName('');
    setEmail('');
    setComment('');
  };

  useEffect(() => {
    getData();
    // Naming The Page
    document.title = `${siteTitle} - ${content.title}`;
  }, [siteTitle, content.title]); // Add dependencies for title updates

  const parentComments = comments.filter(
    (comment) => comment.parentID === null
  );
  const repliesComments = comments.filter(
    (comment) => comment.parentID !== null
  );

  const commentsList = parentComments.map((comment) => {
    const replies = repliesComments.map((reply) => {
      if (reply.parentID === comment._id) {
        return (
          <li key={reply._id}>
            <div className='comment reply'>
              <Gravatar email={reply.email} default='monsterid' size={100} />
              <div className='text'>
                <div className='name'>{reply.name}</div>
                <div className='body'>{reply.comment}</div>
              </div>
            </div>
          </li>
        );
      }
      return null;
    });

    return (
      <li key={comment._id}>
        <div className='comment'>
          <Gravatar email={comment.email} default='monsterid' size={100} />
          <div className='text'>
            <div className='name'>{comment.name}</div>
            <div className='body'>{comment.comment}</div>
            {/*<button>{reply} Reply</button>*/}
          </div>
        </div>
        <ol>{replies}</ol>
      </li>
    );
  });

  return (
    <div className='SingleBlog'>
      {loading && <Loader />}
      <LeftBar />

      <div className='col-md-9 offset-md-3'>
        <div className='all-content'>
          <section>
            <Fade bottom>
              <h3>{content.title}</h3>
            </Fade>

            <Fade bottom>
              <div className='entry'>
                <div
                  className='wrapper'
                  dangerouslySetInnerHTML={{
                    __html: content.content,
                  }}
                />
                <div className='the-end'>
                  <span>The End</span>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className='comments'>
                {comments.length > 0 && (
                  <div className='title'>Comments ({comments.length})</div>
                )}

                <ul>{commentsList}</ul>
                <div className='title mt-5'>Write a comment</div>
                <form onSubmit={addCommentHandler}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        name='full-name'
                        id='full-name'
                        placeholder='Your name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className='col-md-6'>
                      <input
                        type='email'
                        name='e-mail'
                        id='e-mail'
                        placeholder='Your email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className='col-md-12 position-relative'>
                      <textarea
                        name='comment'
                        id='comment'
                        placeholder='Write a comment...'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <button className='send' type='submit'>
                        {send}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Fade>
          </section>
        </div>
      </div>

      <Navigation />
    </div>
  );
};

export default withRouter(SingleBlog);
