import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

// Loader
import Loader from '../../components/Loader/Loader';

// Components
import LeftBar from '../../components/LeftBar/LeftBar';
import Navigation from '../../components/Navigation/Navigation';

// Helpers
import { trimText } from '../../helper/functions';

// Icons
import { newspaper } from '../../helper/icons';

// Animation
import { Fade } from 'react-awesome-reveal';

// Server API
import { getArticles } from '../../api/blog';
import { getInfo } from '../../api/info';
import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';
import './Blog.scss';

const Blog = (props) => {
  const [loading, setLoading] = useState(true);
  const [siteTitle, setSiteTitle] = useState('');
  const [articles, setArticles] = useState([]);

  const getData = async () => {
    try {
      const articlesResponse = await getArticles();
      const infoResponse = await getInfo();

      if (!articlesResponse.success || !infoResponse.success) {
        return toast.error('Something wrong, try again');
      }

      setSiteTitle(
        getObjectByName(infoResponse.data, 'option_name', 'site_title')
          .option_value
      );
      setArticles(articlesResponse.data);
    } catch (error) {
      toast.error('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // Naming The Page
    document.title = `${siteTitle} - Blog`;
  }, [siteTitle]); // Adding siteTitle as a dependency

  // Import Styling
  require('./Blog.scss');
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const articlesList = articles.map((article) => {
    const excerpt = trimText(
      article.content.replace(/<\/?[^>]+(>|$)/g, ''),
      100
    );
    const day = new Date(article.createdAt).getDate();
    const month = new Date(article.createdAt).getMonth();
    return (
      <Fade bottom key={article.id}>
        <div className='col-md-6'>
          <Link to={`/blog/${article._id}`}>
            <div className='blog-card'>
              <div className='thumb'>
                <div className='date'>
                  <span>{day}</span>
                  {monthNames[month]}
                </div>
                <div className='overlay'>{newspaper}</div>
                <img
                  src={
                    process.env.REACT_APP_CONTENT_BASE_URL + article.thumbnail
                  }
                  alt={article.title}
                />
              </div>
              <div className='text'>
                <h2>{article.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: excerpt }} />
              </div>
            </div>
          </Link>
        </div>
      </Fade>
    );
  });

  return (
    <div className='Blog'>
      {loading && <Loader />}
      <LeftBar />

      <div className='col-md-9 offset-md-3'>
        <div className='all-content'>
          <section>
            <Fade bottom>
              <h3>Blog</h3>
            </Fade>

            <div className='cards'>
              <div className='row'>{articlesList}</div>
            </div>
          </section>
        </div>
      </div>

      <Navigation />
    </div>
  );
};

export default withRouter(Blog);
