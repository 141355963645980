import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// Loader
import Loader from '../../components/Loader/Loader';

// Icons
import { globe } from '../../helper/icons';

// Animation
import { Fade } from 'react-awesome-reveal';

// Server API
import { getInfo } from '../../api/info';
import { getProject } from '../../api/portfolio';
import { getObjectByName } from '../../helper/helper';
import withRouter from '../../helper/withRouter';
import './SinglePortfolio.scss';

const SinglePortfolio = (props) => {
  const [loading, setLoading] = useState(true);
  const [siteTitle, setSiteTitle] = useState('');
  const [content, setContent] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [caption, setCaption] = useState('');
  const id = props.router.params.id; // Getting id from router props

  const handlePopup = (imgSRC, cpn) => {
    setImgSrc(imgSRC);
    setOpenPopup(!openPopup);
    setCaption(cpn);
  };

  const getData = async () => {
    try {
      const info = await getInfo();
      const project = await getProject(id);

      if (!project.success || !info.success) {
        throw new Error('Something wrong, try again');
      }

      setSiteTitle(
        getObjectByName(info.data, 'option_name', 'site_title').option_value
      );
      setContent(project.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // Naming the page
    document.title = `${siteTitle} - ${content.name || ''}`;
  }, [siteTitle, content.name]); // Dependencies array

  const styling = {
    display: openPopup ? 'block' : 'none',
  };

  const parsedLinks = content.links ? JSON.parse(content.links) : [];

  const gallery = content.files?.map((img) => (
    <img
      key={img._id}
      className='gallery-image full'
      onClick={() =>
        handlePopup(
          process.env.REACT_APP_CONTENT_BASE_URL + img.path,
          img.caption
        )
      }
      src={process.env.REACT_APP_CONTENT_BASE_URL + img.path}
      alt={`img-${img._id}`}
    />
  ));

  const iframes = content.videos?.map((iframe) => (
    <div
      className='iframeWrapper'
      key={iframe._id}
      dangerouslySetInnerHTML={{ __html: iframe.code }}
    />
  ));

  const tags = content.tools?.map((tag, index) => (
    <span key={index}>{tag.name}</span>
  ));

  const links = parsedLinks.map((link, index) => (
    <div className='link' key={index}>
      {globe}
      <a href={link} rel='noopener noreferrer' target='_blank'>
        {link}
      </a>
    </div>
  ));

  return (
    <div className='SinglePortfolio'>
      {loading && <Loader />}
      <div style={styling} className='popup'>
        <div className='container d-flex align-items-center justify-content-center h-100'>
          <i
            className='fas fa-times close-preview'
            onClick={() => setOpenPopup(false)}
          />
          <div className='wrapper'>
            <img src={imgSrc} alt='Camera' />
            <p>{caption}</p>
          </div>
        </div>
      </div>
      <Link to='/portfolio' className='exit'>
        <i className='fas fa-times' />
      </Link>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <section>
              <Fade bottom>
                <h3>{content.name}</h3>
              </Fade>
              <Fade bottom>
                <div
                  className='contentWrapper'
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
              </Fade>

              <Fade bottom>
                <div className='gallery'>{gallery}</div>
              </Fade>

              <Fade bottom>
                <div className='videos'>{iframes}</div>
              </Fade>
            </section>
          </div>
          <div className='col-md-4'>
            <Fade bottom>
              <div className='sidebar'>
                <h3>Tools & Technologies</h3>
                <div className='tags'>{tags}</div>
                <h3>Links</h3>
                <div className='links'>{links}</div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SinglePortfolio);
