import server from "./server";

const getTestimonials = async () => {
  try {
    const testimonials = await server.get("/testimonials");
    return testimonials.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTestimonial = async (id) => {
  try {
    const testimonial = await server.get(`/testimonials/${id}`);
    return testimonial.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getTestimonials, getTestimonial };
