import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Import Styling
import './Navigation.scss';

// Icons
import { about, home, portfolio, blog } from '../../helper/icons';
import withRouter from '../../helper/withRouter';

class Navigation extends Component {
  render() {
    return (
      <div className='navi'>
        <NavLink to='/'>{home}</NavLink>
        <NavLink to='/about'>{about}</NavLink>
        <NavLink to='/portfolio'>{portfolio}</NavLink>
        <NavLink to='/blog'>{blog}</NavLink>
      </div>
    );
  }
}

export default withRouter(Navigation);
