import React from 'react';
import withRouter from '../../helper/withRouter';

const Loader = () => {
  // Import Styling
  require('./Loader.scss');

  return (
    <div className='loader'>
      <div className='multi-spinner-container'>
        <div className='multi-spinner'>
          <div className='multi-spinner'>
            <div className='multi-spinner'>
              <div className='multi-spinner'>
                <div className='multi-spinner'>
                  <div className='multi-spinner' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Loader);
