import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { Fade } from 'react-awesome-reveal';
import { downloadIcon, contactIcon } from '../../helper/icons';
import LeftBar from '../../components/LeftBar/LeftBar';
import Navigation from '../../components/Navigation/Navigation';
import { trimText } from '../../helper/functions';
import { getInfo } from '../../api/info';
import { getObjectByName } from '../../helper/helper';
import './Home.scss';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const fetchInfo = async () => {
      const info = await getInfo();
      if (!info?.success) {
        toast.error('Something wrong, try again');
        return;
      }
      setInfo(info.data);
      setLoading(false);

      const siteTitle = getObjectByName(
        info.data,
        'option_name',
        'site_title'
      )?.option_value;
      document.title = `${siteTitle ? siteTitle : 'Ankit Shah'} - Home`;
    };

    fetchInfo();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className='Home'>
      {loading && <Loader />}
      <LeftBar />
      <div className='col-md-9 offset-md-3'>
        <div className='all-content'>
          <div className='row align-items-center'>
            <div className='col-md-5'>
              {!loading && (
                <Fade>
                  <div>
                    <img
                      className='pp'
                      src={
                        process.env.REACT_APP_CONTENT_BASE_URL +
                        getObjectByName(info, 'option_name', 'profile_picture')
                          ?.option_value
                      }
                      alt='Ankit'
                    />
                  </div>
                </Fade>
              )}
            </div>
            <div className='col-md-7'>
              <Fade>
                <div className='details'>
                  <h3>I'm</h3>
                  <h1>
                    {
                      getObjectByName(info, 'option_name', 'fullname')
                        ?.option_value
                    }
                  </h1>
                  <span>
                    {
                      getObjectByName(info, 'option_name', 'job_title')
                        ?.option_value
                    }
                  </span>
                  <p>
                    {trimText(
                      getObjectByName(info, 'option_name', 'description')
                        ?.option_value,
                      275
                    )}
                  </p>
                  <div className='links'>
                    <a
                      href={
                        process.env.REACT_APP_CONTENT_BASE_URL +
                        getObjectByName(info, 'option_name', 'cv')?.option_value
                      }
                      target={'_blank'}
                      rel={'noreferrer'}
                      download={true}
                      className='download'
                    >
                      Download CV {downloadIcon}
                    </a>
                    <Link to='/contact' className='contact'>
                      Contact Me {contactIcon}
                    </Link>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <Navigation />
    </div>
  );
};

export default Home;
