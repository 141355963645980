import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

// Pages
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Portfolio from './pages/Portfolio/Portfolio';
import SinglePortfolio from './pages/SinglePortfolio/SinglePortfolio';
import Blog from './pages/Blog/Blog';
import SingleBlog from './pages/SingleBlog/SingleBlog';
import Contact from './pages/Contact/Contact';

// Icons
import { squaresMain } from './helper/icons';

// Server API
import { getInfo } from './api/info';
import { getObjectByName } from './helper/helper';

const App = () => {
  useEffect(() => {
    changeColor();
  }, []);

  const changeColor = async () => {
    const info = await getInfo();
    const color = getObjectByName(
      info?.data,
      'option_name',
      'site_color'
    )?.option_value;
    document.documentElement.style.setProperty(
      '--bg-color',
      `#${color}`,
      'important'
    );
  };

  return (
    <div className='Main'>
      {squaresMain}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/portfolio/:id' element={<SinglePortfolio />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:id' element={<SingleBlog />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
